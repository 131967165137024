import { Role, type User } from "@prisma/client";

import { env } from "./env.server.ts";

import { AuthError } from "#src/utils/errors.ts";
import { isSubset } from "#src/utils/isSubset.ts";

export { Role };

export class InsufficientPermissionsError extends AuthError {
  constructor() {
    super("User is either unauthenticated or is missing required permissions.");
    this.name = "InsufficientPermissionsError";
  }
}

export class UnauthorizedAccessError extends AuthError {
  constructor() {
    super("User lacks permissions to access the requested resource.");
    this.name = "UnauthorizedAccessError";
  }
}

export class UnauthenticatedError extends AuthError {
  constructor() {
    super("User is unauthenticated and has no permissions.");
    this.name = "UnauthenticatedError";
  }
}

export enum Permission {
  BULL_BOARD_VIEW = "BULL_BOARD_VIEW",
  CARE_GAP_VIEW = "CARE_GAP_VIEW",
  CARE_GAP_VIEW_UNRELEASED_CODES = "CARE_GAP_VIEW_UNRELEASED_CODES",
  /** @deprecated: use CCM_BILL_READ */
  CCM_BILLING_NOTE_READ = "CCM_BILLING_NOTE_READ",
  /** @deprecated: use CCM_BILL_WRITE */
  CCM_BILLING_NOTE_WRITE = "CCM_BILLING_NOTE_WRITE",
  CCM_BILL_READ = "CCM_BILL_READ",
  CCM_BILL_READ_ALL = "CCM_BILL_READ_ALL",
  CCM_BILL_WRITE = "CCM_BILL_WRITE",
  CCM_TASKS_FOR_LOGS_READ = "CCM_TASKS_FOR_LOGS_READ",
  CCM_WORK_LOGS_READ = "CCM_WORK_LOGS_READ",
  CCM_WORK_LOG_WRITE = "CCM_WORK_LOG_WRITE",
  CHAT = "CHAT",
  COMMUNITY_CREATE = "COMMUNITY_CREATE",
  COMMUNITY_PROVIDER_ASSIGN = "COMMUNITY_PROVIDER_ASSIGN",
  COMMUNITY_PROVIDER_CONTEXT_VIEW = "COMMUNITY_PROVIDER_CONTEXT_VIEW",
  COMMUNITY_VIEW = "COMMUNITY_VIEW",
  COORDINATOR_METRICS_VIEW = "COORDINATOR_METRICS_VIEW",
  COORDINATOR_METRICS_VIEW_ALL = "COORDINATOR_METRICS_VIEW_ALL",
  DEVELOPER_TOOLS_VIEW = "DEVELOPER_TOOLS_VIEW",
  DIAGNOSIS_PROVIDER_REVIEW = "DIAGNOSIS_PROVIDER_REVIEW",
  DIAGNOSIS_VIEW = "DIAGNOSIS_VIEW",
  ELATION_BILL_WRITE = "ELATION_BILL_WRITE",
  ELIGIBILITY_VIEW = "ELIGIBILITY_VIEW",
  FLEX_TIME_TASK_VIEW = "FLEX_TIME_TASK_VIEW",
  FLEX_TIME_TASK_WRITE = "FLEX_TIME_TASK_WRITE",
  GOOGLE_CALENDAR_ACCESS = "GOOGLE_CALENDAR_ACCESS",
  GQL_MUTATION_EXECUTE = "GQL_MUTATION_EXECUTE",
  GQL_QUERY_EXECUTE = "GQL_QUERY_EXECUTE",
  INTENDED_ACTION_WRITE = "INTENDED_ACTION_WRITE",
  INTROSPECT_SCHEMA = "INTROSPECT_SCHEMA",
  LAB_RESULT_CREATE = "LAB_RESULT_CREATE",
  LLM_TOOLS_VIEW = "LLM_TOOLS_VIEW",
  LOCATION_UPDATE = "LOCATION_UPDATE",
  LOCATION_VIEW = "LOCATION_VIEW",
  MISSED_VISITS_VIEW = "MISSED_VISITS_VIEW",
  MISSED_VISITS_WRITE = "MISSED_VISITS_WRITE",
  PATIENT_CONTACTS_VIEW = "PATIENT_CONTACTS_VIEW",
  PATIENT_CONTACTS_WRITE = "PATIENT_CONTACTS_WRITE",
  PATIENT_LIST_VIEW = "PATIENT_LIST_VIEW",
  PATIENT_NOTES_READ = "PATIENT_NOTES_READ",
  PATIENT_NOTES_WRITE = "PATIENT_NOTES_WRITE",
  PATIENT_VIEW = "PATIENT_VIEW",
  PATIENT_VIEW_ALL = "PATIENT_VIEW_ALL",
  PATIENT_WRITE = "PATIENT_WRITE",
  POPULATION_HEALTH = "POPULATION_HEALTH",
  PROVIDER_METRICS_VIEW = "PROVIDER_METRICS_VIEW",
  PROVIDER_METRICS_VIEW_ALL = "PROVIDER_METRICS_VIEW_ALL",
  PROVIDER_SCHEDULE_VALIDATION_OVERRIDE = "PROVIDER_SCHEDULE_VALIDATION_OVERRIDE",
  PROVIDER_SCHEDULE_VIEW = "PROVIDER_SCHEDULE_VIEW",
  PROVIDER_SCHEDULE_WRITE = "PROVIDER_SCHEDULE_WRITE",
  QMB_STATUS_READ = "QMB_STATUS_READ",
  QMB_STATUS_WRITE = "QMB_STATUS_WRITE",
  SERVER_TIMINGS_VIEW = "SERVER_TIMINGS_VIEW",
  TASK_CREATE = "TASK_CREATE",
  TASK_UPDATE = "TASK_UPDATE",
  TASK_VIEW = "TASK_VIEW",
  USER_ADMIN_CREATE = "USER_ADMIN_CREATE",
  USER_DEACTIVATE = "USER_DEACTIVATE",
  USER_EDIT = "USER_EDIT",
  USER_LIST_VIEW = "USER_LIST_VIEW",
  USER_PRIMARY_CARE_COORDINATOR_CREATE = "USER_PRIMARY_CARE_COORDINATOR_CREATE",
  USER_PRIMARY_CARE_PROVIDER_CREATE = "USER_PRIMARY_CARE_PROVIDER_CREATE",
  USER_VIEW = "USER_VIEW",
  VISIT_NOTE_VIEW = "VISIT_NOTE_VIEW",
  VISIT_NOTE_WRITE = "VISIT_NOTE_WRITE",
}

type PermissionConfig = Record<Role, Permission[]>;
const permissionConfig: PermissionConfig = {
  [Role.DEVELOPER]: [
    Permission.DEVELOPER_TOOLS_VIEW,
    Permission.ELIGIBILITY_VIEW,
  ],
  [Role.ADMIN]: Object.values(Permission).filter(
    p =>
      ![
        Permission.DEVELOPER_TOOLS_VIEW,
        Permission.GOOGLE_CALENDAR_ACCESS,
      ].includes(p)
  ),
  [Role.CCM_BILLING_STAFF]: [
    Permission.CCM_BILL_READ,
    Permission.CCM_BILL_READ_ALL,
    Permission.CCM_WORK_LOGS_READ,
    Permission.COMMUNITY_VIEW,
    Permission.ELIGIBILITY_VIEW,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.QMB_STATUS_READ,
    Permission.QMB_STATUS_WRITE,
    Permission.USER_LIST_VIEW,
    Permission.USER_VIEW,
  ],
  [Role.CODER]: [
    Permission.COMMUNITY_VIEW,
    Permission.DIAGNOSIS_VIEW,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.GQL_QUERY_EXECUTE,
    Permission.LOCATION_VIEW,
    Permission.PATIENT_NOTES_READ,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.USER_VIEW,
  ],
  [Role.COMMUNITY_PARTNER]: [
    // NOTE: these are external users. Access to data
    //       should be gated to only the community
    //       that they are assigned to
    Permission.CHAT,
    Permission.COMMUNITY_VIEW,
    Permission.GQL_QUERY_EXECUTE,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.INTROSPECT_SCHEMA,
    Permission.LOCATION_VIEW,
    Permission.PATIENT_LIST_VIEW,
    Permission.PATIENT_VIEW,
    Permission.USER_VIEW,
  ],
  [Role.ENROLLMENT_DIRECTOR]: [
    Permission.CHAT,
    Permission.COMMUNITY_CREATE,
    Permission.COMMUNITY_VIEW,
    Permission.FLEX_TIME_TASK_VIEW,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.GQL_QUERY_EXECUTE,
    Permission.INTROSPECT_SCHEMA,
    Permission.LOCATION_UPDATE,
    Permission.LOCATION_VIEW,
    Permission.PATIENT_LIST_VIEW,
    Permission.PATIENT_NOTES_READ,
    Permission.PATIENT_NOTES_WRITE,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.PATIENT_WRITE,
    Permission.PROVIDER_SCHEDULE_WRITE,
    Permission.PROVIDER_SCHEDULE_VIEW,
    Permission.USER_EDIT,
    Permission.USER_LIST_VIEW,
    Permission.USER_VIEW,
  ],
  [Role.COMMUNITY_PROVIDER_ASSIGNER]: [
    Permission.COMMUNITY_CREATE,
    Permission.COMMUNITY_PROVIDER_ASSIGN,
    Permission.COMMUNITY_VIEW,
    Permission.PATIENT_LIST_VIEW,
    Permission.USER_DEACTIVATE,
    Permission.USER_LIST_VIEW,
    Permission.USER_PRIMARY_CARE_PROVIDER_CREATE,
    Permission.USER_VIEW,
  ],
  [Role.CLINICAL_TEAM_MANAGER]: [
    Permission.CARE_GAP_VIEW,
    Permission.CCM_BILLING_NOTE_READ,
    Permission.CCM_BILLING_NOTE_WRITE,
    Permission.CCM_BILL_READ,
    Permission.CCM_BILL_READ_ALL,
    Permission.CCM_BILL_WRITE,
    Permission.CCM_TASKS_FOR_LOGS_READ,
    Permission.CCM_WORK_LOGS_READ,
    Permission.CCM_WORK_LOG_WRITE,
    Permission.COMMUNITY_VIEW,
    Permission.DIAGNOSIS_PROVIDER_REVIEW,
    Permission.DIAGNOSIS_VIEW,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.GQL_QUERY_EXECUTE,
    Permission.INTENDED_ACTION_WRITE,
    Permission.LAB_RESULT_CREATE,
    Permission.LOCATION_VIEW,
    Permission.PROVIDER_METRICS_VIEW,
    Permission.PROVIDER_METRICS_VIEW_ALL,
    Permission.PATIENT_NOTES_READ,
    Permission.PATIENT_NOTES_WRITE,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.PATIENT_WRITE,
    Permission.PROVIDER_SCHEDULE_VALIDATION_OVERRIDE,
    Permission.PROVIDER_SCHEDULE_WRITE,
    Permission.PROVIDER_SCHEDULE_VIEW,
    Permission.USER_VIEW,
    Permission.VISIT_NOTE_VIEW,
    Permission.VISIT_NOTE_WRITE,
  ],
  [Role.PRIMARY_CARE_COORDINATOR]: [
    Permission.CHAT,
    Permission.CARE_GAP_VIEW,
    Permission.CCM_BILLING_NOTE_READ,
    Permission.CCM_BILL_READ,
    Permission.CCM_TASKS_FOR_LOGS_READ,
    Permission.CCM_WORK_LOGS_READ,
    Permission.CCM_WORK_LOG_WRITE,
    Permission.COMMUNITY_VIEW,
    Permission.FLEX_TIME_TASK_VIEW,
    Permission.FLEX_TIME_TASK_WRITE,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.GQL_QUERY_EXECUTE,
    Permission.LOCATION_UPDATE,
    Permission.LOCATION_VIEW,
    Permission.COORDINATOR_METRICS_VIEW,
    Permission.PATIENT_LIST_VIEW,
    Permission.PATIENT_NOTES_READ,
    Permission.PATIENT_NOTES_WRITE,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.PATIENT_WRITE,
    Permission.POPULATION_HEALTH,
    Permission.PROVIDER_SCHEDULE_WRITE,
    Permission.PROVIDER_SCHEDULE_VIEW,
    Permission.TASK_UPDATE,
    Permission.TASK_VIEW,
    Permission.USER_LIST_VIEW,
    Permission.USER_VIEW,
    Permission.VISIT_NOTE_VIEW,
    Permission.VISIT_NOTE_WRITE,
  ],
  [Role.PROVIDER_SCHEDULER]: [
    Permission.GOOGLE_CALENDAR_ACCESS,
    Permission.PROVIDER_SCHEDULE_VALIDATION_OVERRIDE,
  ],
  [Role.PROMPT_ENGINEER]: [Permission.LLM_TOOLS_VIEW],
  [Role.PRIMARY_CARE_PROVIDER]: [
    Permission.CARE_GAP_VIEW,
    Permission.CCM_BILLING_NOTE_READ,
    Permission.CCM_BILLING_NOTE_WRITE,
    Permission.CCM_BILL_READ,
    Permission.CCM_BILL_WRITE,
    Permission.CCM_TASKS_FOR_LOGS_READ,
    Permission.CCM_WORK_LOG_WRITE,
    Permission.CCM_WORK_LOGS_READ,
    Permission.CHAT,
    Permission.COMMUNITY_VIEW,
    Permission.DIAGNOSIS_PROVIDER_REVIEW,
    Permission.DIAGNOSIS_VIEW,
    Permission.ELATION_BILL_WRITE,
    Permission.FLEX_TIME_TASK_VIEW,
    Permission.FLEX_TIME_TASK_WRITE,
    Permission.GQL_MUTATION_EXECUTE,
    Permission.GQL_QUERY_EXECUTE,
    Permission.INTENDED_ACTION_WRITE,
    Permission.INTROSPECT_SCHEMA,
    Permission.LAB_RESULT_CREATE,
    Permission.LOCATION_UPDATE,
    Permission.LOCATION_VIEW,
    Permission.MISSED_VISITS_VIEW,
    Permission.MISSED_VISITS_WRITE,
    Permission.PATIENT_LIST_VIEW,
    Permission.PATIENT_NOTES_READ,
    Permission.PATIENT_NOTES_WRITE,
    Permission.PATIENT_VIEW,
    Permission.PATIENT_VIEW_ALL,
    Permission.PATIENT_WRITE,
    Permission.PROVIDER_METRICS_VIEW,
    Permission.PROVIDER_SCHEDULE_VIEW,
    Permission.PROVIDER_SCHEDULE_WRITE,
    Permission.TASK_VIEW,
    Permission.USER_LIST_VIEW,
    Permission.USER_VIEW,
    Permission.VISIT_NOTE_VIEW,
    Permission.VISIT_NOTE_WRITE,
  ],
  get [Role.PROVIDER_ADMIN]() {
    return [
      ...this[Role.COMMUNITY_PROVIDER_ASSIGNER],
      ...this[Role.PRIMARY_CARE_PROVIDER],
    ];
  },
  [Role.SCHEMA_INTROSPECTOR]: [Permission.INTROSPECT_SCHEMA],
  get [Role.PRIMARY_CARE_COORDINATOR_MANAGER]() {
    return [
      ...this[Role.PRIMARY_CARE_COORDINATOR],
      Permission.COORDINATOR_METRICS_VIEW_ALL,
    ];
  },
};

export function permissionsForRole(...roles: Role[]) {
  return roles.flatMap(role => permissionConfig[role] as Permission[]);
}

export function rolesWithPermissions(...permissions: Permission[]): Role[] {
  return Object.values(Role).filter(role =>
    rolesHavePermissions([role], ...permissions)
  );
}

export function hasOnlyCommunityPartnerRole(roles: Role[]) {
  /** we automatically add DEVELOPER to any user on "local", this compensates */
  if (env.APP_ENV === "local") {
    return hasOnlyRoles(roles, [Role.COMMUNITY_PARTNER, Role.DEVELOPER]);
  }

  return hasOnlyRoles(roles, [Role.COMMUNITY_PARTNER]);
}

function hasOnlyRoles(roles: Role[], allowedRoles: string[]): boolean {
  return roles.every(role => allowedRoles.includes(role));
}

function rolesHavePermissions(
  roles: Role[],
  ...requiredPermissions: Permission[]
) {
  return requiredPermissions.every(rp =>
    permissionsForRole(...roles).includes(rp)
  );
}

export function assertUserPermissions(
  user: Pick<User, "roles"> | null,
  ...requiredPermissions: Permission[]
): asserts user is Pick<User, "roles"> {
  if (user === null) {
    throw new UnauthenticatedError();
  }
  const hasPerms = rolesHavePermissions(user.roles, ...requiredPermissions);

  if (!hasPerms) {
    throw new InsufficientPermissionsError();
  }
}

export function userHasPermission(
  user: Pick<User, "roles"> | null,
  ...requiredPermissions: Permission[]
): boolean {
  if (user === null) {
    return false;
  }

  return rolesHavePermissions(user.roles, ...requiredPermissions);
}

export type RoleAssumableUser = Pick<User, "roles">;
export function assumeRoles<U extends RoleAssumableUser>(
  user: U | null,
  roles: Role[]
) {
  if (user == null) {
    return user;
  }

  if (roles.length === 0) {
    return user;
  }

  const grantedPermissions = user.roles.flatMap(role => permissionsForRole(role));
  const allRequiredPermissions = roles.flatMap(role => permissionsForRole(role));
  const hasAllPermissions = isSubset(allRequiredPermissions, grantedPermissions);

  if (!hasAllPermissions) {
    throw new InsufficientPermissionsError();
  }

  return {
    ...user,
    roles,
  };
}
